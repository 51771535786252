<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="项目区域选择"
      width="80%"
      top="5vh"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      class="qmDialog">
        <!-- 项目区域 -->
        <el-container>
            <CommonTree
                ref="commonTreeCore"
                treeTitle="区域分类"
                :treeData="treeData"
                nodeKey="id"
                searchTitle="typeName"
                :defaultProps="{ label: 'typeName' }"
                :expandOnClickNode="false"
                @getNodeClick="handleNodeClick"
            />
            <el-main>
                <head-layout
                    head-title=""
                />
                <grid-head-layout
                    ref="gridHeadLayout"
                    :search-columns="searchColumns"
                    @grid-head-search="gridHeadSearch"
                    @grid-head-empty="gridHeadEmpty"
                ></grid-head-layout>
                <!--  -->
                <grid-layout
                    class="itemListGrid"
                    ref="gridLayOut"
                    :table-options="tableOptions"
                    :table-data="tableData"
                    :table-loading="tableLoading"
                    :data-total="page.total"
                    :page="page"
                    @page-current-change="handleCurrentChange"
                    @page-size-change="handleSizeChange"
                    @page-refresh-change="onLoad"
                    :grid-row-btn="gridRowBtn"
                    @row-change="rowChange"
                >
                </grid-layout>
          </el-main>
        </el-container>
    </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { getAreaClassTree, getAreaClassPage } from "@/api/safe/projectArea";
import { mapGetters } from "vuex";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },

  data() {
    return {
        treeData: [],
        nodeData: {},
        dialogVisible: false,
        tableData: [],
        tableLoading: false,
        page: {
            pageSize: 20,
            currentPage: 1,
            total: 0,
        },
        query: {},
        prjId: "", // 项目id
        selectionList: [],
        searchColumns: [
            {
            label: "",
            prop: "areaName",
            span: 4,
            placeholder: "请输入区域名称",
            },
        ],
    };
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        menu:true,
        selection: false,
        column: [
          {
            label: "区域名称",
            prop: "areaName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "区域编码",
            prop: "areaCode",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "工作范围",
            prop: "remark",
            align: "center",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "更新时间",
            prop: "updateTime",
            align: "center",
            minWidth: 170,
            overHidden: true,
          },
          {
            label: "创建人",
            prop: "createUserName",
            align: "center",
            minWidth: 120,
            overHidden: true,
          },
          {
            label: "创建人单位",
            prop: "createDeptName",
            align: "left",
            minWidth: 200,
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn() {
        return [
          {
            label: '选择',
            emit: "row-change",
            type: "button",
            icon: "",
          },
        ];
    },
  },

  methods: {
    init(prjId) {
        this.dialogVisible = true
        this.prjId = prjId;
        this.query = {};
        this.onLoad(this.page);
        this.getTreeData()
    },

    getTreeData() {
      getAreaClassTree({ prjId: this.prjId }).then((res) => {
        this.treeData = res.data.data;
      });
    },
    handleNodeClick(data, node) {
      this.nodeData = data;
      this.query.typeId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    // 筛选列表
    // 搜索
    gridHeadSearch(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page);
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
        this.tableLoading = true;
        this.page = page;
        params.prjId = this.prjId;
        getAreaClassPage(
            page.currentPage,
            page.pageSize,
            Object.assign(page.currentPage, page.pageSize, params, this.query)
        ).then((res) => {
            const data = res.data.data;
            this.$refs.gridLayOut.page.total = data.total;
            this.tableData = data.records;
            this.tableLoading = false;
            //重新渲染表格，解决勾选框错行问题
            this.$nextTick(() => {
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
            });
            this.$refs.gridLayOut.selectionClear();
        });
    },
    //页数改变
    handleCurrentChange(e) {
        this.page.currentPage = e.currentPage;
        this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
        this.page.currentPage = 1;
        this.page.pageSize = e.pageSize;
        this.onLoad(this.page);
    },

    rowChange(row, index) {
        console.log(row)
        this.$emit('callback',row)
        this.dialogVisible = false
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree {
  // height: calc(100vh - 220px) !important;
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}

::v-deep .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
